/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer-bg-color{
    background-color: #030e22;
}

.footer-section {
    padding: 50px 0 0px;
    overflow: hidden;
    a {
        &:hover,
        &.active {
            & > a {
                color: #d65224 !important;
                background: #0C9DBF;
            }
        }
    }
    // Responsive
    @media #{$tablet-device}{
        padding: 80px 0 0px;
    }
    @media #{$large-mobile}{
        padding: 60px 0 0px;
    }
}

.copyright {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding: 20px 15px;
    border-top: 1px solid $gray-200;
    color: $white;
    margin-bottom: 0;
}